<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="page_tab">
            <div class="btn_wrap">
              <button
                :class="{ on: buttonIndex === 0 }"
                @click="() => (buttonIndex = 0)"
              >
                캐시</button
              ><!--'on'-->
              <button
                :class="{ on: buttonIndex === 1 }"
                @click="() => (buttonIndex = 1)"
              >
                포인트
              </button>
            </div>
          </div>
          <div style="background-color: #fff" v-if="buttonIndex === 0">
            <div class="lump bo_zero">
              <div class="my_point">
                <div class="left">
                  <div>보유캐시</div>
                </div>
                <div class="right">
                  <div>
                    <span class="color">{{
                      $Util.formatNumber2(summary.cash, {
                        decimalLength: 1,
                        unit: ''
                      })
                    }}</span>
                    C
                  </div>
                  <div class="btn_wrap">
                    <button class="btn bo" @click="fnCashWithdraw">출금</button>
                    <button class="btn bo" @click="fnCashCharge">충전</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color: #fff" v-else-if="buttonIndex === 1">
            <div class="lump">
              <div class="my_point">
                <div class="left">
                  <div>보유 포인트</div>
                </div>
                <div class="right">
                  <div>
                    <span class="color">{{
                      $Util.formatNumber2(summary.point, { unit: '' })
                    }}</span>
                    P
                  </div>
                </div>
              </div>
              <div class="hint">
                * 포인트 사용은 발행일로부터 1년이며, 기간 내 미사용 포인트는
                자동으로 삭제됩니다.
              </div>
            </div>
          </div>

          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">검색 기간</button>
              </div>
              <div class="right"></div>
            </div>
            <div class="con_btn">
              <div class="btn_wrap" style="margin-bottom: -1rem;">
                <button
                  :class="{ bo: searchKind !== 0 }"
                  class="btn"
                  @click="searchKind = 0"
                >
                  1주일
                </button>
                <button
                  :class="{ bo: searchKind !== 1 }"
                  class="btn"
                  @click="searchKind = 1"
                >
                  1개월
                </button>
                <button
                  :class="{ bo: searchKind !== 2 }"
                  class="btn"
                  @click="searchKind = 2"
                >
                  3개월
                </button>
              </div>
            </div>
            <div class="con_btn">
              <div class="btn_wrap" style="margin-bottom: 0;">
                <div class="double_date_round">{{ subDate }}</div>
                <div class="double_date_round">{{ todayDate }}</div>
              </div>
            </div>
            <div class="cash_point_table">
              <div class="cash_point_table_paper" v-if="buttonIndex === 0">
                <dl>
                  <dd class="cash_point_table_row">
                    충전 캐시
                    <span class="plus">{{
                      $Util.formatNumber2(summary.charge, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    판매 적립
                    <span class="plus">{{
                      $Util.formatNumber2(summary.gain, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    환불 캐시
                    <span class="plus">{{
                      $Util.formatNumber2(summary.refund, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row mt">
                    충전 취소
                    <span class="minus">{{
                      $Util.formatNumber2(summary.chargeCancel, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    사용 캐시
                    <span class="minus">{{
                      $Util.formatNumber2(summary.using, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    출금 캐시
                    <span class="minus">{{
                      $Util.formatNumber2(summary.withdraw, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    출금 수수료
                    <span class="minus">{{
                      $Util.formatNumber2(summary.withdrawFee, { unit: '' })
                    }}</span>
                  </dd>
                </dl>
              </div>
              <div class="cash_point_table_paper" v-if="buttonIndex === 1">
                <dl>
                  <dd class="cash_point_table_row">
                    적립 포인트
                    <span class="plus">{{
                      $Util.formatNumber2(summary.gain, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row mt">
                    사용 포인트
                    <span class="minus">{{
                      $Util.formatNumber2(summary.using, { unit: '' })
                    }}</span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="party_list_wrap" v-if="!$Util.isEmpty(list)">
            <div class="party_list row_type">
              <!--'가로형: row_type'-->
              <ul class="party_list_ul">
                <!-- 일자별 한묶음 -->
                <template v-for="(item, i) in list" :key="i">
                  <li class="cap_date">
                    <div class="right">
                      {{ $dayjs(item.dt).format('YYYY.MM.DD') }}
                    </div>
                  </li>
                  <li
                    class="party_list_li double"
                    @click="fnSummaryDetail(item)"
                  >
                    <div class="party_tag">
                      <div class="left">
                        <div
                          class="pt_tag logo sl"
                          style="--pt-bg-color: #00ADEF;--pt-txt-color: #fff;"
                        >
                          적립
                        </div>
                      </div>
                      <div class="right">
                        <div class="party_price">
                          <span class="value">{{
                            $Util.formatNumber2(item.gain, { unit: '' })
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="party_tag">
                      <div class="left">
                        <div
                          class="pt_tag logo sl"
                          style="--pt-bg-color: #999;--pt-txt-color: #fff;"
                        >
                          사용
                        </div>
                      </div>
                      <div class="right">
                        <div class="party_price">
                          <span class="value gray">{{
                            $Util.formatNumber2(item.using, { unit: '' })
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="empty_wrap" v-else>
            <div class="empty">
              <div>
                검색된 결과가 없습니다.
              </div>
            </div>
          </div>
          <observe-visible
            :key="initCnt"
            :page="page"
            :limit="limit"
            :totalCnt="totalCnt"
            @fetch="fnListFunction"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-adjustment-index',
  setup() {
    return { ...componentState() }
  }
}
</script>
