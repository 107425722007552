import {
  computed,
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
  watch
} from 'vue'
import { useRouter, useRoute } from 'vue-router'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 999

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    paramsPointSummary: computed({
      get: () => ({
        searchKind: state.searchKind
      })
    }),
    paramsPointSummaryList: computed({
      get: () => ({
        searchKind: state.searchKind,
        page: state.page + 1
      })
    }),
    paramsCashSummaryList: computed({
      get: () => ({
        searchKind: state.searchKind,
        page: state.page + 1
      })
    }),
    paramsCashSummary: computed({
      get: () => ({
        searchKind: state.searchKind
      })
    }),
    buttonIndex: Number(route.query.index) || 0,
    searchKind: 0,
    summary: {
      charge: 0,
      gain: 0,
      using: 0,
      withdraw: 0,
      withdrawFee: 0,
      refund: 0,
      cash: 0,
      point: 0
    },
    todayDate: proxy.$dayjs().format('YYYY-MM-DD'),
    subDate: computed(() =>
      state.searchKind === 0
        ? proxy
            .$dayjs()
            .subtract(6, 'days')
            .format('YYYY-MM-DD')
        : state.searchKind === 1
        ? proxy
            .$dayjs()
            .subtract(1, 'month')
            .format('YYYY-MM-DD')
        : proxy
            .$dayjs()
            .subtract(3, 'month')
            .format('YYYY-MM-DD')
    )
  })

  const fnListFunction = async () => {
    let res = {}
    if (state.buttonIndex === 0) {
      res = await proxy.$MyPageSvc.postMyWizzCashCashSummaryList(
        state.paramsCashSummaryList
      )
    } else {
      res = await proxy.$MyPageSvc.postMyWizzPointPointSummaryList(
        state.paramsPointSummaryList
      )
    }
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.totalPoint = res.totalPoint
      state.list = state.list.concat(res.list)
    }
  }

  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }
  const fnCashWithdraw = async () => {
    await router.push({ path: '/my-page/cash-point/withdraw' })
  }
  const fnCashCharge = () => router.push({ name: 'my-page-cash-charge-index' })

  const fnInfo = async () => {
    const res = await proxy.$MyPageSvc.postMyWizzCashWithdrawAccountInfo()
    if (res.resultCode === '0000') {
      state.cash = res.cash
    }
  }

  const getCashAndPointSummary = async () => {
    let res = {}
    if (state.buttonIndex === 0) {
      res = await proxy.$MyPageSvc.postMyWizzCashCashSummary(
        state.paramsCashSummary
      )
    } else {
      res = await proxy.$MyPageSvc.postMyWizzPointPointSummary(
        state.paramsPointSummary
      )
    }
    if (res.resultCode === '0000') {
      const summary = { ...res }
      delete summary.resultCode
      delete summary.resultMsg
      state.summary = { ...summary }
    }
  }

  const fnSummaryDetail = async item => {
    if (state.buttonIndex === 0) {
      await router.push({
        name: 'my-page-adjustment-detail-index',
        params: { name: 'cash', dt: item.dt }
      })
    } else {
      await router.push({
        name: 'my-page-adjustment-detail-index',
        params: { name: 'point', dt: item.dt }
      })
    }
  }

  /** watch **/
  watch(
    () => state.buttonIndex,
    () => {
      getCashAndPointSummary()
      fnReset()
      state.initCnt++
    }
  )

  watch(
    () => state.searchKind,
    () => {
      getCashAndPointSummary()
      fnReset()
      state.initCnt++
    }
  )

  //init
  getCashAndPointSummary()

  onMounted(async () => {
    await fnInfo()
  })

  return {
    ...toRefs(state),
    fnListFunction,
    fnCashWithdraw,
    fnSummaryDetail,
    fnCashCharge
  }
}
